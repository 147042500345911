<template lang="html">
  <div class="activity">
    <img class="head" src="../../assets/image/activityBk.png" alt="">
     <div class="content">
          <div class="list">
            <div class="box" @click="contractShow = true">
              <div class="left">
                合同选择
              </div>
              <div class="right">
                <div class="tag">
                  <span>{{contract_name}}</span>
                  <van-icon name="arrow-down" />
                </div>
              </div>
            </div>
          </div>
          </div>
              <div class="times" v-if="detail">
      <div>
        合同开始时间:<span>{{start}}</span>
      </div>
      <div>合同结束时间:<span>{{end}}</span></div>
    </div>
    <div class="activityBok" v-if="detail">
      <div class="merchantsRent">
        <div class="title">
          商铺信息
        </div>
        <div class="content">
          <div class="list" v-for="(item, index) in detail.before" :key="index">
            <div class="name">
              商铺号：{{item.merchantsStallNumber}}
            </div>
            <div class="box">
              <div class="left">
                租金到期时间：{{item.beforeMerchantsStallEndTime}}
              </div>
              <div class="right">
                {{item.merchantsStallPrice}}元/月
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="merchantsRent">
        <div class="title">
          预缴租金
        </div>
        <div class="content">
          <div class="list">
            <div class="box" @click="show = true">
              <div class="left">
                预缴时间：
              </div>
              <div class="right">
                <div class="tag">
                  <span>{{prepayName}}</span>
                  <van-icon name="arrow-down" />
                </div>
              </div>
            </div>
          </div>
          <div class="list">
            <div class="box">
              <div class="left">
                折扣率：
              </div>
              <div class="right">
                {{detail.beforehandMoney.discount}}折
              </div>
            </div>
          </div>
          <div class="list">
            <div class="box">
              <div class="left">
                实际应缴金额：
              </div>
              <div class="right">
                ￥{{detail.beforehandMoney.money}}
              </div>
            </div>
          </div>
          <div class="list">
            <div class="box">
              <div class="left">
                优惠金额：
              </div>
              <div class="right">
                <div class="tag">
                  ￥{{detail.beforehandMoney.subFloat64}}
                </div>
              </div>
            </div>
          </div>
          <div class="list">
            <div class="box">
              <div class="left">
                实际缴纳金额：
              </div>
              <div class="right">
                <div class="tag">
                  ￥{{detail.beforehandMoney.actualMoneyFloat64}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="merchantsRent">
        <div class="title">
          预缴后商铺信息
        </div>
        <div class="content">
          <div class="list" v-for="(item, index) in detail.after" :key="index">
            <div class="name">
              商铺号：{{item.merchantsStallNumber}}
            </div>
            <div class="box">
              <div class="left">
                租金到期时间：<span>{{item.afterMerchantsStallEndTime}}</span>
              </div>
              <div class="right">
                {{item.merchantsStallPrice}}元/月
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="color:#999999;font-size:12px">注：预缴月数不能超出合同期限</div>
    </div>
    <div class="btn" v-if="detail">
      <van-button round type="primary" color="#FE9F09" @click="confirm">
        <span style="color: #000000;">参与活动</span>
      </van-button>
    </div>
    <van-action-sheet v-model:show="show" title="请选择预缴月数" :actions="actions" @select="onSelect" />
  </div>
   <van-action-sheet v-model:show="contractShow" title="选择合同" :actions="contract_list" @select="contractSelect" />
</template>

<script>
export default {
  name: 'activity',
  data() {
    return {
      show: false,
      prepayName: '一个月',
      prepayValue: 1,
      detail: null,
      userInfo: null,
      contract_id: '',
      contract_name: '',
      contractShow: false,
      contract_list: [],
      start: '',
      end: '',
      actions: [{
          name: '一个月',
          value: 1
        },
        {
          name: '二个月',
          value: 2
        },
        {
          name: '三个月',
          value: 3
        },
        {
          name: '四个月',
          value: 4
        },
        {
          name: '五个月',
          value: 5
        },
        {
          name: '六个月',
          value: 6
        },
        {
          name: '七个月',
          value: 7
        },
        {
          name: '八个月',
          value: 8
        },
        {
          name: '九个月',
          value: 9
        },
        {
          name: '十个月',
          value: 10
        },
        {
          name: '十一个月',
          value: 11
        },
        {
          name: '十二个月',
          value: 12
        }
      ]
    };
  },
  created() {
    this.userInfo = JSON.parse(window.localStorage.getItem('propertyUserInfo'));
    // this.getDetail();
    this.getContract();
    this.contractShow = true;
  },
  methods: {
    getDetail() {
      this.$require.HttpPost('/h5/water_electricity_gas_rent/beforehandDiscount', {
        beforehand_month: this.prepayValue,
        contract_id: this.contract_id
      }).then(res => {
        this.detail = res.data;
        this.prepayValue = res.data.beforehandMoney.month;
        switch (res.data.beforehandMoney.month) {
          case 1:
            this.prepayName = '一个月';
            break;
          case 2:
            this.prepayName = '二个月';
            break;
          case 3:
            this.prepayName = '三个月';
            break;
          case 4:
            this.prepayName = '四个月';
            break;
          case 5:
            this.prepayName = '五个月';
            break;
          case 6:
            this.prepayName = '六个月';
            break;
          case 7:
            this.prepayName = '七个月';
            break;
          case 8:
            this.prepayName = '八个月';
            break;
          case 9:
            this.prepayName = '九个月';
            break;
          case 10:
            this.prepayName = '十个月';
            break;
          case 11:
            this.prepayName = '十一个月';
            break;
          case 12:
            this.prepayName = '十二个月';
            break;
          default:
            this.prepayName = '';
        }
      });
    },
     getContract() {
           this.$require.HttpPost('/h5/contract/contractNo').then(res => {
        if (res.data) {
          res.data.forEach(item => {
            this.contract_list.push({
 name: item.contract_no, value: item.contract_id, start: item.contract_start, end: item.contract_end
});
          });
          this.merchants_stall_list = res.data;
          // console.log(this.contract_list);
        }
      });
      },
      contractSelect(e) {
        this.contract_name = e.name;
         this.contract_id = e.value;
         this.start = e.start;
         this.end = e.end;
         this.getDetail();
         this.contractShow = false;
      },
    onSelect(event) {
      this.prepayName = event.name;
      this.prepayValue = event.value;
      this.show = false;
      this.getDetail();
    },
    confirm() {
        // this.$dialog.alert({
        //   title: '提醒！',
        //   message: `尊敬的${this.userInfo.merchantsAdmin.name}您好！检测的您的合同截止时间在您参与活动的时间内到期，若您参与本次活动，请在合同到期前三天联系市场工作人员续签即可，我们也会在合同到期前联系您进行续签。请确认是否继续参与本次活动。如有疑问请联系：14521213656`,
        //   messageAlign: 'left',
        //   theme: 'round-button',
        //   showCancelButton: true,
        //   confirmButtonColor: '#FE9F09'
        // }).then(() => {
          this.beforehand();
        // });
    },
    beforehand() {
      this.$require.HttpPost('/h5/water_electricity_gas_rent/addRentBillOfflineANDBill', {
        beforehand_month: this.prepayValue,
        bill_key: 40,
        contract_id: this.contract_id
      }).then((res) => {
        this.$require.HttpPost('/h5/water_electricity_gas_rent/changeMessageRent', {
          bill_offline_no: res.data
        }).then(() => {
          this.$toast('缴费成功');
          setTimeout(() => {
            this.$router.go(-1);
          }, 500);
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .times{
    font-size: 25px;
    color: #000000;
    padding: 0 25px;
    // font-weight: bolder;
    span{
      font-size: 20px;
      color: #999999;
      margin-left:15px
    }
  }
.content {
                .list {
                    margin-top: 20px;
                    .name {
                        position: relative;
                        font-size: 28px;
                        color: #666666;
                        padding-left: 30px;
                        &:before {
                            content: ' ';
                            width: 18px;
                            height: 14px;
                            background: #FE9F09;
                            border-radius: 0 7px 7px 0;
                            position: absolute;
                            left: 0;
                            top: 7px;
                        }
                    }
                    .box {
                        padding-left: 30px;
                        padding-right: 55px;
                        display: flex;
                        justify-content: space-between;
                        color: #000000;
                        font-size: 28px;
                        .left {
                            span {
                                color: #FE9F09;
                            }
                        }
                        .right {
                            .tag {
                                color: #FE9F09;
                            }
                            .van-icon {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
.activity {
    .head {
        width: 100%;
        height: 760px;
    }
    .activityBok {
        padding: 0 24px;
        .merchantsRent {
            background: #FFFFFF;
            box-shadow: 0 0 25px 0 rgba(57, 57, 57, 0.06);
            border-radius: 30px;
            margin-top: 44px;
            padding: 30px 0;
            .title {
                width: 262px;
                height: 78px;
                line-height: 78px;
                background: linear-gradient(90deg, #FEC161 0%, #FFE6BD 100%);
                border-radius: 0 39px 39px 0;
                font-size: 32px;
                font-family: PingFang-SC-Bold;
                font-weight: bold;
                color: #E44F00;
                text-align: center;
            }
            .content {
                .list {
                    margin-top: 20px;
                    .name {
                        position: relative;
                        font-size: 28px;
                        color: #666666;
                        padding-left: 30px;
                        &:before {
                            content: ' ';
                            width: 18px;
                            height: 14px;
                            background: #FE9F09;
                            border-radius: 0 7px 7px 0;
                            position: absolute;
                            left: 0;
                            top: 7px;
                        }
                    }
                    .box {
                        padding-left: 30px;
                        padding-right: 55px;
                        display: flex;
                        justify-content: space-between;
                        color: #000000;
                        font-size: 28px;
                        .left {
                            span {
                                color: #FE9F09;
                            }
                        }
                        .right {
                            .tag {
                                color: #FE9F09;
                            }
                            .van-icon {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .btn {
        width: 468px;
        height: 96px;
        margin: 50px auto 0;
    }
}
</style>
